'use client';

import { ActionButton, Alert, Tag } from '@/components/shared';
import { ENV, Environment, EnvironmentBackgroundImageMap, EnvironmentColorMap } from '@/utils/env';
import { signIn } from 'next-auth/react';
import Image from 'next/image';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import PodiLogoDark from '@/public/images/logos/podi-dark.svg';
import PodiLogoLight from '@/public/images/logos/podi-light.svg';
export default function SignInPage() {
  const searchParams = useSearchParams();
  // TODO: reevaluate callbackUrl redirect on signIn
  // const callbackUrl = searchParams.get('callbackUrl');
  // await signIn(authProviderId, callbackUrl ? { callbackUrl: callbackUrl } : undefined);
  const errorParam = searchParams.get('error');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const handleSignIn = async () => {
    const authProviderId = process.env.NEXT_PUBLIC_AUTH_PROVIDER_ID?.toLowerCase() as string;
    try {
      setLoading(true);
      await signIn(authProviderId);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!errorParam) return;
    switch (errorParam) {
      case 'SessionRequired':
        setErrorMessage('Your session has expired.');
        break;
      case 'AccessDenied':
        setErrorMessage('Unable to validate. Please contact your supervisor regarding access to Achilles.');
        break;
    }
  }, [errorParam]);
  return <main id="SignInPage" className="relative flex h-full w-full flex-row gap-2" data-sentry-component="SignInPage" data-sentry-source-file="SignInPage.tsx">
      <Image src={PodiLogoDark.src} width={150} height={75} className="absolute left-8 top-6 z-10 object-contain" alt="Podimetrics Logo - Black" data-sentry-element="Image" data-sentry-source-file="SignInPage.tsx" />
      <Image src={PodiLogoLight.src} width={250} height={125} className="absolute right-8 top-6 z-10" alt="Podimetrics Logo - White" data-sentry-element="Image" data-sentry-source-file="SignInPage.tsx" />
      <div className="flex h-full w-full flex-col items-center justify-center bg-white px-10 sm:max-w-lg">
        <div className="flex w-full flex-col items-start gap-2 sm:max-w-sm">
          {ENV && [Environment.LOCAL, Environment.DEV, Environment.UAT].includes(ENV) && <Tag type={EnvironmentColorMap[ENV]}>{ENV} Mode</Tag>}
          <h2 className="pb-0 pt-2 text-3xl font-bold">Welcome</h2>
          {errorMessage && <Alert id={`${errorParam}Alert`} message={errorMessage} />}
          <small className="pb-2 text-gray-500">Sign in to your account to get started</small>
          <ActionButton id="SignInButton" className="w-full" onClick={handleSignIn} loading={loading} text={loading ? 'Signing In... ' : 'Sign In '} data-sentry-element="ActionButton" data-sentry-source-file="SignInPage.tsx" />
        </div>
      </div>
      <div className="relative hidden w-full sm:block">
        <Image priority src={EnvironmentBackgroundImageMap[ENV as Environment]?.src || ''} className="relative z-0 object-cover" alt="Podimetrics Background Image" layout="fill" data-sentry-element="Image" data-sentry-source-file="SignInPage.tsx" />
      </div>
    </main>;
}